.footer {
  background-color: var(--black);
  color: var(--white);
  border-top: 3px solid var(--brand-color-main);
  text-align: center;
  padding: 1rem 0.75rem 2.5rem;

  padding-bottom: calc(2.5rem + constant(safe-area-inset-bottom));
  padding-bottom: calc(2.5rem + env(safe-area-inset-bottom));
}

.version {
  font-size: 0.5em;
  overflow-wrap: break-word;
  display: block;
  margin-top: 1em;
}
